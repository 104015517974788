import "./styles.scss";
import {Link, useLocation} from "react-router-dom";
import React, {useContext, useState} from "react";
import SideNav from "../sideNav/sideNav";
// import MarketPlace from "./marketPlace/marketPlace";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faRedo } from "@fortawesome/free-solid-svg-icons";
// import CreateTask from "../createTask/createTask";
import RegularButton from "../generalButton/RegularButton";
import {PostsContext} from "../../context/postContext/postContext";
import {CharityContext} from "../../context/charityContext/charityContext";
import {DonationsContext} from "../../context/donationsContext/donationContext";

import {CHARITY, CUSTOMERS, DASHBOARD, DONATIONS, POST,} from "../../routes";

const Header = () => {
    const location = useLocation();

    const {setAddPostModal, setAddPostAnimation} = useContext(PostsContext);

    const {setAddCharityModal, setAddCharityModalAnimation} =
        useContext(CharityContext);

    const {setAddDonationsModal, setAddDonationModalAnimation} =
        useContext(DonationsContext);

    const [showSideNav, setShowSideNav] = useState(false);

    const toggleSideNav = () => {
        setShowSideNav(!showSideNav);
    };

    return (
        <div className="header">
            <div className="menu-button" onClick={toggleSideNav}>
                <div></div>
                <div></div>
                <div></div>
            </div>

            <div className="logo">
                <Link to={DASHBOARD}>
                    <img src="/logo512.png" width={40} alt=""/>
                    <span style={{fontSize: 20, fontWeight: 'bold', color: 'white'}}> KISWAH APP</span>
                </Link>
            </div>

            {/* DASHBOARD HEADERS */}
            {location.pathname === "/dashboard" ||
            location.pathname === "/dashboard/" ? (
                <React.Fragment>
                    <div className="filters">
                        <div className="map-list">
                            {/*<NavLink activeClassName="is-active" exact to={DASHBOARD}>*/}
                            {/*    <i className="fa fa-map-o"></i>*/}
                            {/*    Maps*/}
                            {/*</NavLink>*/}
                            {/* <NavLink
                activeClassName="is-active"
                exact
                to={LIST}
                className="list"
              >
                <i className="fa fa-list-ul"></i>
                List
              </NavLink> */}
                        </div>

                        <div className="date-time">
                            {/*<FilterDate/>*/}
                        </div>

                        {/*<div className="teams">*/}
                        {/*  <FilterTeams />*/}
                        {/*</div>*/}
                    </div>

                    {/* <div style={{ alignSelf: "center", marginLeft: "auto" }}>
            <CreateTask />
          </div> */}

                    {/* <div className="header-marketplace">
            <MarketPlace />
          </div> */}

                    {/* <div
            className="refresh-page"
            onClick={() => window.location.reload()}
          >
            <FontAwesomeIcon icon={faRedo} className="refresh-page-icon" />
          </div> */}
                </React.Fragment>
            ) : (
                ""
            )}

            {/* CUSTOMER HEADERS */}
            {location.pathname === CUSTOMERS ||
            location.pathname === `${CUSTOMERS}/` ? (
                <div className="customer-header">
                    {/*<DropdownFilter*/}
                    {/*    options={["All Customers", "Form Users Only"]}*/}
                    {/*    selectOption={(e) => setHeaderFilter(e.target.innerText)}*/}
                    {/*    backdrop={false}*/}
                    {/*    optionSelected={headerFilter}*/}
                    {/*/>*/}
                    {/*<RegularButton*/}
                    {/*    buttonText="Add Customer"*/}
                    {/*    buttonClick={() => {*/}
                    {/*        setAddCustomerAnimation(true);*/}
                    {/*        setAddCustomerModal(true);*/}
                    {/*    }}*/}
                    {/*/>*/}
                </div>
            ) : (
                ""
            )}

            {/* POST HEADERS */}
            {location.pathname === POST || location.pathname === `${POST}/` ? (
                <div className="post-header">
                    <RegularButton
                        buttonText="Add New Post"
                        buttonClick={() => {
                            setAddPostModal(true);
                            setAddPostAnimation(true);
                        }}
                    />
                </div>
            ) : (
                ""
            )}

            {/* Charity HEADERS */}
            {location.pathname === CHARITY || location.pathname === `${CHARITY}/` ? (
                <div className="charity-header">
                    <RegularButton
                        buttonText="Add New Charity"
                        buttonClick={() => {
                            setAddCharityModal(true);
                            setAddCharityModalAnimation(true);
                        }}
                    />
                </div>
            ) : (
                ""
            )}

            {/* DONATION HEADERS */}
            {location.pathname === DONATIONS ||
            location.pathname === `${DONATIONS}/` ? (
                <div className="doantion-header">
                    <RegularButton
                        buttonText="Add New Donation"
                        buttonClick={() => {
                            setAddDonationsModal(true);
                            setAddDonationModalAnimation(true);
                        }}
                    />
                </div>
            ) : (
                ""
            )}

            {/*<div className="mobile-filter">*/}
            {/*  <MobileFilters />*/}
            {/*</div>*/}

            {/* SIDE NAV DRAWER */}
            <SideNav toggleSideNav={toggleSideNav} showSideNav={showSideNav}/>
        </div>
    );
};

export default Header;
