import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Switch, Table } from "antd";
import { useContext, useEffect, useState } from "react";
import DropdownFilter from "../../../components/dropdownFilter/dropdownFilter";
import TaskDetails from "../../../components/rightTaskDetails/rightTaskDetails";
import { TaskContext } from "../../../context/taskcontext/taskContext";
import "./styles.scss";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    width: 70,
  },
  {
    title: "TEAM NAME",
    dataIndex: "team_name",
    key: "Name",
  },
  {
    title: "TYPE",
    dataIndex: "Type",
    key: "Type",
  },
  {
    title: "DETAILS",
    dataIndex: "Details",
    key: "Details",
  },
  {
    title: "NAME",
    dataIndex: "Name",
    key: "Name",
  },
  {
    title: "ADDRESS",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "START BEFORE",
    dataIndex: "start_before",
    key: "start_before",
  },
  {
    title: "COMPLETE BEFORE",
    dataIndex: "complete_before",
    key: "complete_before",
  },
  {
    title: "REVIEW RATING",
    dataIndex: "review_rating",
    key: "review_rating",
  },
  {
    title: "STATUS",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Action",
    dataIndex: "Action",
    key: "action",
    width: 100,
  },
];

const DashboardList = () => {
  const { fetchUnassignedTask, unassignedTask } = useContext(TaskContext);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // setSelectedRows(selectedRowKeys);
    },
  };

  useEffect(() => {
    fetchUnassignedTask();
    // eslint-disable-next-line
  }, []);
  const [taskDetails, setTaskDetails] = useState();
  const [showTaskDetails, setShowTaskDetails] = useState(false);
  const [filterOne, setFilterOne] = useState("Task ID");
  const [filterTwo, setFilterTwo] = useState("Exact Search");
  // const [filterThree, setFilterThree] = useState("Task ID");
  // const [filterFour, setFilterFour] = useState("Task ID");
  // const [filterFive, setFilterFive] = useState("Task ID");

  return (
    <div className="tasks-list">
      <div className="table-pages-header">
        <div className="search-bar">
          <i className="fa fa-search"></i>
          <input type="text" placeholder="Search Customer" />
        </div>

        <div className="filter">
          <button className="search-btn">Search</button>
          <div className="mr-10">
            <DropdownFilter
              options={[
                "Task ID",
                "Order ID",
                "Name",
                "Address",
                "Description",
                "Agent Details",
                "Team Name",
              ]}
              selectOption={(e) => setFilterOne(e.target.innerText)}
              backdrop={false}
              optionSelected={filterOne}
            />
          </div>

          <div className="mr-10">
            <DropdownFilter
              options={["Exact Search", "Starts with", "Ends with", "Contains"]}
              selectOption={(e) => setFilterTwo(e.target.innerText)}
              backdrop={false}
              optionSelected={filterTwo}
            />
          </div>

          <div className="mr-10">
            <DropdownFilter
              options={["Exact Search", "Starts with", "Ends with", "Contains"]}
              selectOption={(e) => setFilterTwo(e.target.innerText)}
              backdrop={false}
              optionSelected={filterTwo}
            />
          </div>

          <div className="mr-10">
            <DropdownFilter
              options={["Exact Search", "Starts with", "Ends with", "Contains"]}
              selectOption={(e) => setFilterTwo(e.target.innerText)}
              backdrop={false}
              optionSelected={filterTwo}
            />
          </div>

          <div className="mr-10">
            <DropdownFilter
              options={["Exact Search", "Starts with", "Ends with", "Contains"]}
              selectOption={(e) => setFilterTwo(e.target.innerText)}
              backdrop={false}
              optionSelected={filterTwo}
            />
          </div>

          <div className="options">
            <div className="merge-conected-task">
              <span>Merge connected task</span>
              <Switch />
            </div>
            <div className="ml-10">
              <FontAwesomeIcon icon={faRedo} className="icon" />
            </div>
            <div className="ml-10">
              <span className="material-icons icon">
                format_indent_increase
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="task-table">
        <Table
          columns={columns}
          pagination={false}
          className="table"
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          scroll={{ x: "1200px", y: "calc(100vh - 300px)" }}
          dataSource={unassignedTask.map((task) => ({
            key: "task.uuid",
            id: (
              <button
                onClick={() => {
                  setTaskDetails(task);
                  setShowTaskDetails(true);
                }}
                className="taskid-id"
              >
                {task.id}
              </button>
            ),

            team_name: "Name",

            Type: "Type",

            Details: "Details",

            Name: "Task name",

            address: "Task Address",

            start_before: "Task start before",

            complete_before: "task complete before",

            review_rating: "rating",

            status: " status",

            Action: (
              <div className="more">
                <span className="material-icons more-icon">more_horiz</span>
                <ul className="dropdown">
                  <li className="option">Edit</li>
                  <li className="option">Delete</li>
                </ul>
              </div>
            ),
          }))}
        />
      </div>

      {/* TaskDetails */}
      <TaskDetails
        visibility={showTaskDetails}
        onClose={() => setShowTaskDetails(false)}
        taskDetails={taskDetails}
      />
    </div>
  );
};

export default DashboardList;
