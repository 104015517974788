import "./styles.scss";
import {useContext} from "react";
import PaneHeader from "../../../../components/PaneHeader/PaneHeader";
import UnassignedTask from "./unassignedTask/unassignedTask";
import TaskDetailsTab from "../../../../components/taskDetailsTab/taskDetailsTab";
import {TaskContext} from "../../../../context/taskcontext/taskContext";

const DashboardTask = () => {
    const {showTaskDetails, taskDetails,} = useContext(TaskContext);

    return (
        <div className="dashboard-task">
            <PaneHeader
                tabName="Task"
                animateSearch={showTaskDetails}
                showid={showTaskDetails}
                taskDetails={taskDetails}
            />
            <UnassignedTask/>
            {showTaskDetails && <TaskDetailsTab/>}
        </div>
    );
};

export default DashboardTask;
