import "./styles.scss";
import {Pagination, Table} from "antd";
import {useContext, useEffect, useState} from "react";
import GeneralButton from "../../../components/generalButton/AcceptButton";
import {CustomerContext} from "../../../context/customerContext/customerContext";
import Form from "../../../components/customerForm/customerForm";
import CustomerProfile from "../../../components/customerProfile/CustomerProfile";
import CustomerTaskDetails from "../../../components/rightTaskDetails/rightTaskDetails";

const columns = [
    {
        title: "USERNAME",
        dataIndex: "id",
        key: "id"
    },
    {
        title: "NAME",
        dataIndex: "Name",
        key: "Name"
    },
    {
        title: "PHONE",
        dataIndex: "Phone",
        key: "phone"
    },
    {
        title: "EMAIL",
        dataIndex: "Email",
        key: "email"
    },
    {
        title: "COMPANY",
        dataIndex: "Company",
        key: "company"
    },
    {
        title: "ADDRESS",
        dataIndex: "address",
        key: "address"
    },
    {
        title: "Action",
        dataIndex: "Action",
        key: "action",
        width: 100
    }
];

const Customers = () => {
    const {
        getCustomerData,
        customers,
        totalCustomers,
        setCurrentPage,
        currentPage,
        setSelectedRows,
        selectedRows,
        setEditCustomerModal,
        editCustomerModal,
        setCustomerDetails,
        editCustomerAnimation,
        setEditCustomerAnimation,
        setCustomer,
        showCustomerTaskDetails,
        setShowCustomerTaskDetails,
        taskDetails
    } = useContext(CustomerContext);

    const [search, setSearch] = useState('')
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRowKeys);
        }
    };

    useEffect(() => {
        getCustomerData(currentPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage,]);

    useEffect(() => {
        if (search.length > 3) {
            getCustomerData(currentPage,search);
        }
        if(search.length === 0){
            getCustomerData(currentPage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    return (
        <div className="customers">
            <div className="table-pages-header large">
                <div className="search-bar">
                    <i className="fa fa-search"/>
                    <input type="text" value={search} onChange={e => setSearch(e.target.value)}
                           placeholder="Search Customer"/>
                </div>

                {/*<div className="filter">*/}
                {/*<div sclassName="filter-one">*/}
                {/*    <DropdownFilter*/}
                {/*        options={filterOneOptions}*/}
                {/*        selectOption={e => setFilterOne(e.target.innerText)}*/}
                {/*        backdrop={false}*/}
                {/*        optionSelected={filterOne}*/}
                {/*    />*/}
                {/*</div>*/}

                {/*<div className="filter-two">*/}
                {/*    <DropdownFilter*/}
                {/*        options={filterTwoOptions}*/}
                {/*        selectOption={e => setFilterTwo(e.target.innerText)}*/}
                {/*        backdrop={false}*/}
                {/*        optionSelected={filterTwo}*/}
                {/*    />*/}
                {/*</div>*/}
                {/*</div>*/}

                <div className="delete-btn">
                    {selectedRows.length >= 1 ? <GeneralButton buttonText="Delete"/> : null}
                </div>
            </div>

            <div className="table-pages-header mobile">
                {selectedRows.length >= 1 ? null :
                    <div className="search-bar">
                        <i className="fa fa-search"/>
                        <input type="text" placeholder="Search Customer"/>
                    </div>}

                <div className="delete-btn">
                    {selectedRows.length >= 1 ? <GeneralButton buttonText="Delete"/> : null}
                </div>
            </div>

            <div className="customers-table">
                <Table
                    columns={columns}
                    pagination={false}
                    className="table"
                    rowSelection={{
                        type: "checkbox",
                        ...rowSelection
                    }}
                    scroll={{x: "1200px", y: "calc(100vh - 300px)"}}
                    dataSource={customers.map(customer => {
                        const locations = customer?.locations || [];
                        const addr = locations.find(s => {
                            if (s.is_default === 1) {
                                return s;
                            }
                            return {};
                        });
                        if (addr && addr.hasOwnProperty("latitude")) {
                            addr.map =
                                "https://maps.google.com/?q=" +
                                addr.latitude + "," + addr.longitude;
                        }
                        return {
                            key: customer.uuid,
                            id: (
                                <button
                                    onClick={() => setCustomerDetails(true)}
                                    className="customers-username"
                                >{customer.username}</button>
                            ),
                            Name: customer.name,
                            Phone: customer.phone,
                            Email: customer.email,
                            Company: customer.company,
                            address: addr?.address,
                            Action: (
                                <div className="more">
                                    <span className="material-icons more-icon">more_horiz</span>

                                    <ul className="dropdown">
                                        <li
                                            className="option"
                                            onClick={() => {
                                                setEditCustomerModal(true);
                                                setCustomer(customer)
                                                setEditCustomerAnimation(true);
                                            }}
                                        >
                                            Edit
                                        </li>
                                        <li className="option">Delete</li>
                                    </ul>
                                </div>
                            )
                        };
                    })}
                />
                {totalCustomers && (
                    <Pagination
                        defaultCurrent={1}
                        total={totalCustomers}
                        onChange={page => setCurrentPage(page)}
                        style={{textAlign: "right", marginTop: "20px"}}
                    />
                )}
            </div>

            {/*{addCustomerModal && (*/}
            {/*    <div className="modal-wrapper">*/}
            {/*        <div className="backdrop"/>*/}
            {/*        <div className={`modal ${addCustomerAnimation ? "fade-in" : "fade-out"}`}>*/}
            {/*            <Form*/}
            {/*                formHeading="Add Customer"*/}
            {/*                cancelBtn={() => {*/}
            {/*                    setAddCustomerAnimation(false);*/}
            {/*                    setTimeout(() => setAddCustomerModal(false), 600);*/}
            {/*                }}*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*)}*/}

            {editCustomerModal && (
                <div className="modal-wrapper">
                    <div className="backdrop"/>
                    <div className={`modal ${editCustomerAnimation ? "fade-in" : "fade-out"}`}>
                        <Form
                            formHeading="Edit Customer"

                            cancelBtn={() => {
                                setEditCustomerAnimation(false);
                                setTimeout(() => setEditCustomerModal(false), 600);
                            }}
                        />
                    </div>
                </div>
            )}

            {/* customer details */}
            <CustomerProfile/>

            {/* customer Task details */}
            <CustomerTaskDetails
                visibility={showCustomerTaskDetails}
                onClose={() => setShowCustomerTaskDetails(false)}
                taskDetails={taskDetails}
            />
        </div>
    );
};

export default Customers;
