import "./styles.scss";

import TaskCard from "../../../../../components/taskCard/taskCard";
import {useContext, useEffect, useRef, useState} from "react";
import {TaskContext} from "../../../../../context/taskcontext/taskContext";
import {AppService} from "../../../../../services";
import {catchAuthError} from "../../../../../utils";
import Loading from "../../../../../components/loading/loading";

const UnassignedTask = () => {
    const {showDetailsFunction,} = useContext(TaskContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [justFetched, setJustFetched] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const prevScrollY = useRef(0);
    const [goingDown, setGoingDown] = useState(false);

    const onScroll = (e) => {
        console.log('scrolling',  e.target.scrollTop)
        const currentScrollY = e.target.scrollTop;
        const _80 = (e.target.scrollHeight - 556) * 0.98;
        if (prevScrollY.current < currentScrollY && !goingDown) {
            setGoingDown(true);
        }
        if (prevScrollY.current > currentScrollY && goingDown) {
            setGoingDown(false);
        }
        prevScrollY.current = currentScrollY;

        if (goingDown && (currentScrollY > _80)) {
            if (isLoading || justFetched === currentPage || currentPage > totalPages) {
                return
            }
            fetchData()
        }
    };

    useEffect(() => {
        const taskDiv = document.getElementById('task-div')

        function watchScroll() {
            taskDiv?.addEventListener("scroll", onScroll);
        }
        watchScroll();
        // Remove listener (like componentWillUnmount)
        return () => {
            taskDiv?.removeEventListener("scroll", onScroll);
        };
        // eslint-disable-next-line
    }, []);

    const sortData = ({data: {data: {data: new_data, last_page, current_page}}}) => {
        setData([...data, ...new_data])
        setTotalPages(last_page)
        setCurrentPage(current_page + 1)
        setJustFetched(current_page)
    }

    const fetchData = () => {
        if (currentPage > totalPages) return;
        setLoading(true)
        AppService.getTasks(currentPage, search).then(sortData).catch(catchAuthError)
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setCurrentPage(1);
        setData([])
        fetchData()
        // eslint-disable-next-line
    }, [search])


    return (
        <div>
            <input
                style={{
                    height: 45,
                    border: 'none',
                    width: '100%',
                    padding: '5px 20px',
                    marginBottom: 8,
                    borderBottom: 'solid 1px #99999950'
                }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search "/>
            {isLoading && <div style={{position: 'relative'}}>
                <Loading/>
            </div>}
            <div id="task-div" className="unassigned-task" style={{overflowY: "scroll"}}>

                {data.map(task => <TaskCard key={task.id} task={task} showMore={() => showDetailsFunction(task)}/>)}
            </div>

        </div>

    );
};

export default UnassignedTask;
