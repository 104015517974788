import "./styles.scss";
import React, {Component, useContext} from "react";
import GoogleMapReact from "google-map-react";
import {TaskContext} from "../../../../context/taskcontext/taskContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";

const Map = ({pins, lat, lng, zoom}) => {
    const {unassignedTask, assignedTask, completedTask} = useContext(TaskContext);
    const data = [...unassignedTask, ...assignedTask, ...completedTask];
    return (
        <div className="dashboard-map">
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: "AIzaSyDdHMB87WgSAdWlbEiORryX6ttcBiIwJC8",
                    language: "en"
                }}
                defaultCenter={{lng: 46.6753, lat: 24.7136}}
                defaultZoom={zoom}
                center={{lat, lng}}
            >
                {data?.map(({id, location, ...data}) => {
                    if (location) {
                        return (
                            <MyMarker
                                key={id}
                                lat={location?.latitude}
                                lng={location?.longitude}
                                text={id}
                                data={data}
                                tooltip={data.title}
                            />
                        );
                    }
                    return null;
                })}

                {pins?.map(({id, ...data}) => {
                    return (
                        <MyMarker
                            key={id}
                            lat={data?.latitude}
                            lng={data?.longitude}
                            tooltip={data.title}
                        />
                    );
                })}
            </GoogleMapReact>
        </div>
    );
};

Map.defaultProps = {
    pins: [], zoom: 5, lng: 46.6753, lat: 24.7136,
}


class MyMarker extends Component {
    state = {clicked: false};
    render() {
        return <FontAwesomeIcon icon={faMapMarkerAlt} className="map-pin"/>;
    }
}

export default Map;
