import "./styles.scss";
import React, {useState} from "react";
import {Select, Switch} from "antd";
import CancelButton from "../generalButton/cancelButton";
import AcceptButton from "../generalButton/AcceptButton";

// const example = {
//     "id": 25,
//     "order_id": "ORD422123",
//     "job_description": "required by the backend",
//     "job_delivery_datetime": "2021-07-07 00:00:00",
//     "is_pickup": 1,
//     "tags": [
//         "Clothes"
//     ],
//     "donations": [
//         "2"
//     ],
//     "meta_data": null,
//     "ref_images": null,
//     "created_at": "2021-07-05T13:56:37.000000Z",
//     "tookan_id": "288954967",
//     "job_status": 6,
//     "task_history": [
//         {
//             "id": 1730779700,
//             "job_id": 288954967,
//             "fleet_id": null,
//             "fleet_name": null,
//             "latitude": null,
//             "longitude": null,
//             "type": "state_changed",
//             "description": "Created By API - kiswa Salman Alzahrani - 90162",
//             "extra_fields": null,
//             "creation_datetime": "2021-07-05T13:56:38.000Z",
//             "creation_date": "2021-07-05T00:00:00.000Z",
//             "label_description": "<span class='created'>CREATED</span>By API - kiswa Salman Alzahrani - 90162"
//         }
//     ],
//     "tracking_link": "https://jngl.ml/5CfCc53a4",
//     "arrived_datetime": "0000-00-00 00:00:00",
//     "started_datetime": "0000-00-00 00:00:00",
//     "completed_datetime": "0000-00-00 00:00:00",
//     "acknowledged_datetime": "0000-00-00 00:00:00",
//     "task_id": 25,
//     "requested": "2021-07-05 13:56:37",
//     "agent_assigned": null,
//     "on_way": null,
//     "picked": null,
//     "completed": null,
//     "status_readable": "Request Sent",
//     "donation_types": [
//         {
//             "id": 2,
//             "name": "Clothes",
//             "icon": "https://res.cloudinary.com/gidicodes/image/upload/v1621255374/kiswah/uploads/phppZsdDn_uzfd7s.png",
//             "is_available": 1,
//             "created_at": "2021-05-12T00:44:04.000000Z",
//             "updated_at": "2021-05-17T12:42:54.000000Z",
//             "is_acceptable": 1
//         }
//     ],
//     "location": null,
//     "status": {
//         "requested": "2021-07-05 13:56:37",
//         "agent_assigned": null,
//         "on_way": null,
//         "picked": null,
//         "completed": null,
//         "status_readable": "Request Sent",
//         "status_index": 0
//     },
//     "customer": {
//         "uuid": "39fbf86f-4574-4ada-b9a6-03962b69f0ba",
//         "name": "Suhaib",
//         "username": "halawani",
//         "email": "isuhaib.h@gmail.com",
//         "avatar": null,
//         "phone": "+966567161718",
//         "created_at": "2021-07-05T12:55:38.000000Z",
//         "deleted_at": null
//     }
// }

const TaskCard = ({task, showMore}) => {
    const {Option} = Select;
    // console.log(task)

    const humanTime = (dateObject) => {
        let time = new Date(dateObject);
        let date = time.toDateString() + ' ' + time;
        return date;
    };

    const [assignTaskModal, setAssignTaskModal] = useState(false);

    return (
        <React.Fragment>
            <div className="task-card">
                {(task.donation_types.length) && (
                    <div className="agent-details">
                        <img src={task.donation_types[0].icon} alt=""/>
                        <div className="agent-name">{task.donation_types[0].name}</div>
                    </div>
                )}

                <div className="task-details-wrapper" onClick={showMore}>
                    <div className="task-details">
                        <div className="timing-and-status">
                            <div className="timing">{task.requested || humanTime(task.job_delivery_datetime)}</div>
                            <div className="status">
                                <div
                                    className={`task-status ${['assigned', 'started', 'successful', 'failed', 'in-progress',
                                        'unassigned', 'unassigned', 'accepted', 'declined', 'cancelled',
                                        'deleted'][task.job_status || 6]}`}>
                                    {['Assigned', 'Started', 'Successful', 'Failed', 'In Progress',
                                        'Unassigned', 'Unassigned', 'Accepted', 'Declined', 'Cancelled',
                                        'Deleted'][task.job_status || 6] || 'UNSIGNED'}
                                </div>
                            </div>
                        </div>
                        <div className="client-name">{task?.customer?.name}</div>
                        <div className="client-name">{task?.customer?.phone}</div>
                        <div className="client-name">{task?.customer?.email}</div>
                        <div className="address">{task?.location?.address}</div>
                        {/*<div className="task-status show-on-map-label">show on map</div>*/}
                    </div>
                </div>

                {/*<span className="material-icons show-more-icon">*/}
                {/*  keyboard_arrow_right*/}
                {/*</span>*/}

                {/*{task && (*/}
                {/*    <div className="delay-status">*/}
                {/*        <span className="material-icons icon">query_builder</span>*/}
                {/*        Delayed*/}
                {/*    </div>*/}
                {/*)}*/}
            </div>

            {assignTaskModal && (
                <React.Fragment>
                    <div className="assign-task-modal">
                        <div
                            className="backdrop"
                            onClick={() => setAssignTaskModal(false)}
                        ></div>
                        <div className="modal">
                            <form>
                                <div className="modal-header">
                                    <h3>Task Id: 1</h3>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <div className="form-col">
                                            <Select
                                                showSearch
                                                style={{width: "100%"}}
                                                placeholder="Search Team"
                                                optionFilterProp="children"
                                            >
                                                <Option value="1">Not Identified</Option>
                                                <Option value="2">Closed</Option>
                                            </Select>
                                        </div>
                                        <div className="form-col">
                                            <Switch/>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="form-col">
                                            <Select
                                                showSearch
                                                className="selects"
                                                mode="multiple"
                                                style={{width: "100%"}}
                                                placeholder="Search Tag"
                                                optionFilterProp="children"
                                            >
                                                <Option value="1">Not Identified</Option>
                                                <Option value="2">Not Identified</Option>
                                                <Option value="3">Not Identified</Option>
                                            </Select>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="form-col">
                                            <label className="form-label">Assign</label>
                                            <Select
                                                showSearch
                                                style={{width: "100%"}}
                                                placeholder="Assign"
                                                optionFilterProp="children"
                                            >
                                                <Option value="1">Not Identified</Option>
                                            </Select>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="form-col">
                                            <label className="form-label">Staus</label>
                                            <Select
                                                style={{width: "100%"}}
                                                placeholder="Assign"
                                                optionFilterProp="children"
                                            >
                                                <Option value="1">Unassigned</Option>
                                                <Option value="2">Assigned</Option>
                                                <Option value="3">Approved</Option>
                                                <Option value="4">Inprogress</Option>
                                                <Option value="5">Completed</Option>
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-footer">
                  <span style={{marginRight: "10px"}}>
                    <CancelButton
                        buttonText="Cancel"
                        buttonClick={() => setAssignTaskModal(false)}
                    />
                  </span>
                                    <AcceptButton buttonText="Add" submit/>
                                </div>
                            </form>
                        </div>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default TaskCard;
