import "./styles.scss";

import React, { useState } from "react";

import AcceptButton from "../generalButton/AcceptButton";
import CancelButton from "../generalButton/cancelButton";

const DonationForm =
    ({ formTitle, btnText, onFormSubmit, closeModal, initialName, acceptability, isEdit }) => {
        const submit = e => {
            e.preventDefault();
            if (donationName) {
                onFormSubmit(e);
            } else {
                setFormError(true);
                setTimeout(() => {
                    setFormError(false);
                }, 2500);
            }
        };
        let [donationName, setDonationName] = useState(initialName || "");
        let [is_acceptable, setAvailability] = useState(acceptability || "2");

        let [formError, setFormError] = useState(false);

        const setA = ({ target: { value } }) => setAvailability(value)

        return (
            <React.Fragment>
                <div className="post-form">
                    <div className="form-header fs-30">
                        <h3>{formTitle}</h3>
                    </div>
                    {formError && (<p style={{ color: "red", fontSize: "12px" }}>Fill all required field</p>)}
                    <form onSubmit={submit} method="post" encType="multipart/form-data">
                        <div className="form-row">
                            <div className="form-col">
                                <label className="required">Name: </label>
                                <input
                                    name="name"
                                    type="text"
                                    value={donationName}
                                    onChange={e => setDonationName(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-col">
                                <label className="aligned">
                                    <span>Available: </span>
                                    <input type="radio" onChange={setA} checked={String(is_acceptable).includes('1')} value={1}
                                        name="is_acceptable" />
                                </label>
                                <label className="aligned">
                                    <span>Not Available: </span>
                                    <input type="radio" onChange={setA} checked={String(is_acceptable) === '2'} value={2}
                                        name="is_acceptable" />
                                </label>
                                <label className="aligned">
                                    <span>Not acceptable: </span>
                                    <input type="radio" onChange={setA} checked={String(is_acceptable) === '3'} value={3}
                                        name="is_acceptable" />
                                </label>
                            </div>
                        </div>


                        <div className="form-row">
                            <div className="form-col">
                                <label>Select Image</label>
                                <input type="file" accept="image/*" name="icon" required={!isEdit} />
                            </div>
                        </div>

                        <div className="form-footer ">
                            <span style={{ marginRight: "10px" }}>
                                <CancelButton buttonText="Cancel" buttonClick={closeModal} />
                            </span>
                            <AcceptButton buttonText={btnText} submit />
                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    };

export default DonationForm;
