import "./styles.scss";

import * as api from "../../../constants/baseUri";

import {Pagination, Table} from "antd";
import {useContext, useEffect, useState} from "react";

import {DonationsContext} from "../../../context/donationsContext/donationContext";
import Form from "../../../components/donationsForm/donationsForm";
import GeneralButton from "../../../components/generalButton/AcceptButton";
import {GeneralContext} from "../../../context/generalContext/generalContext";
import axios from "axios";

const columns = [
    {
        title: "ID",
        dataIndex: "id",
        key: "id",
        width: 70
        // render: text => <a>{text}</a>,
    },
    {
        title: "Name",
        dataIndex: "Name",
        key: "name"
    },
    // {
    //     title: "Avialabilty",
    //     dataIndex: "Avialabilty",
    //     key: "avialable"
    // },
    {
        title: "Avialabilty/Acceptable",
        dataIndex: "Acceptable",
        key: "acceptable"
    },
    {
        title: "Action",
        dataIndex: "Action",
        key: "action",
        width: 90
    }
];

const Donations = () => {
    // getting state from auth Contex
    const {setLoading, showAlert} = useContext(GeneralContext);

    const {
        addDonationsModal,
        setAddDonationsModal,
        addDonationModalAnimation,
        setAddDonationModalAnimation
    } = useContext(DonationsContext);

    // base uri
    const BASE_URI = api.BASE_URI;

    // state for current page
    let [currentPage, setCurrentPage] = useState(1);

    // state for donations data
    const [donations, setDonations] = useState([]);
    const [donation, setDonation] = useState({});
    const [totalDonations, setTotalDonations] = useState(null);

    // get news donations
    const getDonations = async paagNumber => {
        setLoading(true);
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("user-token")}`
            }
        };

        try {
            const dataReturned = await axios.get(
                `${BASE_URI}/donations?page=${paagNumber}`,
                config
            );

            setLoading(false);
            setDonations(dataReturned.data.data.data);
            setTotalDonations(dataReturned.data.data.total);
        } catch (err) {
            setLoading(false);
            showAlert("error", err.message);
        }
    };

    // fuction for creating new news
    const addDonations = async e => {
        setLoading(true);

        let data = new FormData(e.target);

        let config = {
            method: "post",
            url: `${BASE_URI}/donations`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("user-token")}`
            },
            data: data
        };

        try {
            await axios(config);
            setLoading(false);
            showAlert("success", `Donations Added`);
            setAddDonationsModal(false);
            // eslint-disable-next-line
            getDonations();
        } catch (err) {
            setLoading(false);
            showAlert("error", err.message);
        }
    };

    // modal state for update news
    const [updateDonationsModal, setUpdateDonationsModal] = useState(false);
    // state for updated news ID
    const [updateDonationsID, setUpdateDonationsID] = useState(0);

    const [editDonationModalAnimation, setEditDonationModalAnimation] =
        useState(false);
    const editDonations = donation => {
        setUpdateDonationsModal(true);
        setEditDonationModalAnimation(true);
        setUpdateDonationsID(donation.id);
        setDonation(donation);
    };

    // fuction for editing news
    const updateDonations = async e => {
        let data = new FormData(e.target);
        setLoading(true);
        let config = {
            method: "post",
            url: `${BASE_URI}/donations/${updateDonationsID}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("user-token")}`
            },
            data: data
        };
        try {
            // eslint-disable-next-line
            const dataReturned = await axios(config);
            setLoading(false);
            setUpdateDonationsModal(false);
            showAlert("success", `Donations Updated`);
            getDonations();
        } catch (err) {
            showAlert("error", err.message);
            setLoading(false);

        }
    };

    // function for deleting news
    const deleteDonation = async id => {
        setLoading(true);
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("user-token")}`
            }
        };

        try {
            // eslint-disable-next-line
            let datareturned = await axios.delete(`${BASE_URI}/donations/${id}`, config);
            setLoading(false);
            showAlert("success", `Donation (${id}) Deleted`);
            // eslint-disable-next-line
            getDonations();
        } catch (err) {
            setLoading(false);
            showAlert("error", err.message);
        }
    };

    // state for selected rows
    const [selectedRows, setSelectedRows] = useState([]);

    // function for deleting multile news
    const deleteMultiple = () => {
        setLoading(true);
        const config = {headers: {Authorization: `Bearer ${localStorage.getItem("user-token")}`}};
        try {
            axios.all(selectedRows.map(donationid => axios.delete(`${BASE_URI}/donations/${donationid}`, config)));
            setSelectedRows([]);
            setLoading(false);
            showAlert("success", `Donations Deleted`);
            getDonations();
        } catch (err) {
            showAlert("error", err.message);
            setLoading(false)
        }
    };

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        const userToken = localStorage.getItem("user-token");

        if (user && userToken) {
            getDonations(currentPage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRowKeys);
        }
    };

    return (
        <div className="donations">
            <div className="table-pages-header large">
                <div className="search-bar">
                    {/*<i className="fa fa-search"></i>*/}
                    {/*<input type="text" placeholder="Search Donation"/>*/}
                </div>

                <div className="delete-btn">
                    {selectedRows.length >= 1 ? (
                        <GeneralButton buttonText="Delete" buttonClick={deleteMultiple}/>
                    ) : (
                        ""
                    )}
                </div>
            </div>

            <div className="table-pages-header mobile">
                {selectedRows.length >= 1 ? (
                    ""
                ) : (
                    <div className="search-bar">
                        {/*<i className="fa fa-search"></i>*/}
                        {/*<input type="text" placeholder="Search Donation"/>*/}
                    </div>
                )}

                <div className="delete-btn">
                    {selectedRows.length >= 1 ? (
                        <GeneralButton buttonText="Delete" buttonClick={deleteMultiple}/>
                    ) : (
                        ""
                    )}
                </div>
            </div>

            <div className="donations-table">
                <Table
                    columns={columns}
                    pagination={false}
                    className="table"
                    rowSelection={{
                        type: "checkbox",
                        ...rowSelection
                    }}
                    scroll={{x: "1200px", y: "calc(100vh - 300px)"}}
                    dataSource={donations.map(donation => ({
                        key: donation.id,
                        id: donation.id,

                        Name: (
                            <div>
                                <img
                                    className="images"
                                    alt="donation-icon"
                                    src={donation.icon}
                                />
                                {donation.name}
                            </div>
                        ),

                        Acceptable: ["Available", "Not Available", "Not Acceptable"][donation?.is_acceptable - 1] || "Not Available",
                        Action: (
                            <div className="more">
                                <span className="material-icons more-icon">more_horiz</span>
                                <ul className="dropdown">
                                    <li
                                        className="option"
                                        onClick={() => editDonations(donation)}
                                    >
                                        Edit
                                    </li>
                                    <li
                                        className="option"
                                        onClick={() => deleteDonation(donation.id)}
                                    >
                                        Delete
                                    </li>
                                </ul>
                            </div>
                        )
                    }))}
                />
                {totalDonations && (
                    <Pagination
                        defaultCurrent={1} total={totalDonations} onChange={page => setCurrentPage(page)}
                        style={{textAlign: "right", marginTop: "20px"}}
                    />
                )}
            </div>

            {addDonationsModal && (
                <div className="modal-wrapper">
                    <div className="backdrop"/>
                    <div className={`modal ${addDonationModalAnimation ? "fade-in" : "fade-out"}`}>
                        <Form
                            formTitle="Add Donation"
                            btnText="Add Donation"
                            onFormSubmit={addDonations}
                            closeModal={() => {
                                setAddDonationModalAnimation(false);
                                setTimeout(() => setAddDonationsModal(false), 600);
                            }}
                        />
                    </div>
                </div>
            )}

            {updateDonationsModal && (
                <div className="modal-wrapper">
                    <div className="backdrop"/>
                    <div className={`modal ${editDonationModalAnimation ? "fade-in" : "fade-out"}`}>
                        <Form
                            formTitle={`Update Donation: ${donation.name}`}
                            initialName={donation.name}
                            acceptability={Number(donation?.is_acceptable) >= 1 ? donation.is_acceptable : 2}
                            btnText="Update Donation"
                            isEdit
                            onFormSubmit={updateDonations}
                            closeModal={() => {
                                setEditDonationModalAnimation(false);
                                setTimeout(() => setUpdateDonationsModal(false), 600);
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Donations;
