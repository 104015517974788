import Request from "../utils/Request";

class RegionService {
    subUrl = 'admin/region'
    all = (page = 1, search = '') => Request().get(`${this.subUrl}?page=${page}${search && '&search=' + search}`);
    single = (id) => Request().get(`${this.subUrl}/${id}`);
    update = (id, form_data) => Request().post(`${this.subUrl}/${id}`, form_data);
    delete = (id) => Request().delete(`${this.subUrl}/${id}`);
    create = (form_data) => Request().post(`${this.subUrl}`, form_data);
}

export default new RegionService();


