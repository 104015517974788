import "./styles.scss";
import { Drawer } from "antd";
import TabsComponent from "../Tabs/tabs";
import CloseButton from "../closeBotton/closeBotton";
import { TaskContext } from "../../context/taskcontext/taskContext";
import { useContext } from "react";
import TaskDetails from "./taskDetails/taskDetails";
import TaskHistory from "./taskHistory/taskHistory";
import CustomerDetails from "./customerDetails/customerDetails";

const TaskDetailsTab = () => {
    const {showTaskDetails, hideDetailsFunction, taskDetails} = useContext(TaskContext);

    const dashboardTaskDetailsTabs = [
        {
            tabName: "Details",
            tabContent: <TaskDetails details={taskDetails}/>,
            tabIndex: 1
        },
        {
            tabName: "Customers",
            tabContent: <CustomerDetails details={taskDetails}/>,
            tabIndex: 2
        },
        {
            tabName: "History",
            tabContent: <TaskHistory data={taskDetails?.task_history || []}/>,
            tabIndex: 3
        }
    ];

    // const moreButton = (
    //     <div className="more">
    //         <span className="material-icons more-icon">more_vert</span>
    //         <ul className="dropdown">
    //             <li className="option">Edit stop</li>
    //             <li className="option">Duplicate stop</li>
    //             <li className="option">Export stop</li>
    //             <li className="option">Delete stop</li>
    //             <li className="option">Reassign the delegate</li>
    //             <li className="option">Change stop status</li>
    //         </ul>
    //     </div>
    // );

    return (
        <div className="task-details-tab">
            <div className="details-tab-drawer">
                <Drawer
                    title="Basic Drawer"
                    placement="bottom"
                    closable={true}
                    onClose={hideDetailsFunction}
                    visible={showTaskDetails}
                    getContainer={false}
                    style={{position: "absolute"}}
                >
                    <button onClick={hideDetailsFunction} className={`close-detalis-btn ${showTaskDetails ? "show-btn" : ""}`}>
                        <CloseButton/>
                    </button>

                    <TabsComponent tabItems={dashboardTaskDetailsTabs}/>
                </Drawer>
            </div>
        </div>
    );
};

export default TaskDetailsTab;
