import "./styles.scss";

import * as api from "../../../constants/baseUri";

import {Pagination, Table} from "antd";
import React, {useContext, useEffect, useState} from "react";

import {CharityContext} from "../../../context/charityContext/charityContext";
import Form from "../../../components/charityForm/charityForm";
import GeneralButton from "../../../components/generalButton/AcceptButton";
import {GeneralContext} from "../../../context/generalContext/generalContext";
import axios from "axios";

const columns = [
    {
        title: "ID",
        dataIndex: "id",
        key: "id",
        width: 70
    },
    {
        title: "Name",
        dataIndex: "Name",
        key: "Name",
        width: 200
    },
    {
        title: "Description",
        dataIndex: "Description",
        key: "Description"
    },
    {
        title: "Action",
        dataIndex: "Action",
        key: "action",
        width: 90
    }
];

const Charities = () => {
    // getting values from auth Contex
    const {setLoading, showAlert} = useContext(GeneralContext);

    // getting values from auth Contex
    const {
        addCharityModal,
        setAddCharityModal,
        addCharityModalAnimation,
        setAddCharityModalAnimation
    } = useContext(CharityContext);

    // base uri
    const BASE_URI = api.BASE_URI;

    // state for current page
    let [currentPage, setCurrentPage] = useState(1);
    let [search, setSearch] = useState('');

    // state for news data
    const [charityData, setCharityData] = useState([]);

    // total charity
    const [totalCharity, setTotalCharity] = useState(null);

    // get charity function
    const getCharityData = async page => {
        setLoading(true);
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("user-token")}`
            }
        };

        try {
            const dataReturned = await axios.get(
              `${BASE_URI}/charities?page=${page}${search ? `&search=${search}` : ''}`,
                config
            );

            setLoading(false);
            setCharityData(dataReturned.data.data.data);
            setTotalCharity(dataReturned.data.data.total);
        } catch (err) {
            showAlert("error", err.message);
        }
    };

    // fuction for creating new charity
    const addNewCharity = async e => {
        setLoading(true);

        let data = new FormData(e.target);

        const config = {
            method: "post", url: `${BASE_URI}/charities`, headers: {
                Authorization: `Bearer ${localStorage.getItem("user-token")}`
            }, data: data
        };

        try {
            // eslint-disable-next-line
            const dataReturned = await axios(config);
            setLoading(false);
            showAlert("success", "Charity Added");
            setAddCharityModal(false);
            getCharityData();
        } catch (err) {
            showAlert("error", err.message);
        }
    };

    // modal state for update charity
    const [updateCharityModal, setUpdateCharityModal] = useState(false);

    // modal animation state for update charity
    const [editCharityAnimation, setEditCharityAnimation] = useState(false);

    // state for selected charity
    const [selectedCharity, setSelectedCharity] = useState({});

    const editCharity = news => {
        setUpdateCharityModal(true);
        setEditCharityAnimation(true);
        setSelectedCharity(news);
    };

    // fuction for editing news
    const updateCharity = async e => {
        let data = new FormData(e.target);
        setLoading(true);

        let config = {
            method: "post",
            url: `${BASE_URI}/charities/${selectedCharity.id}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("user-token")}`
            },
            data: data
        };
        try {
            // eslint-disable-next-line
            const userDataReturned = await axios(config);

            setLoading(false);
            setUpdateCharityModal(false);
            showAlert("success", "Charity Updated");
            getCharityData(currentPage);
        } catch (err) {
            console.log(err);
        }
    };

    // function for deleting charity
    const deleteCharity = async id => {
        setLoading(true);
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("user-token")}`
            }
        };

        try {
            // eslint-disable-next-line
            let datareturned = await axios.delete(
                `${BASE_URI}/charities/${id}`,
                config
            );

            setLoading(false);
            showAlert("success", "Charity Updated");
            getCharityData(currentPage);
        } catch (err) {
            console.log(err);
        }
    };

    // function for deleting multile news
    const deleteMultiple = () => {
        setLoading(true);
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("user-token")}`
            }
        };
        try {
            axios.all(selectedRows.map(charityId => axios.delete(`${BASE_URI}/news/${charityId}`, config)));
            setSelectedRows([]);
            showAlert("success", "Successfully Deleted");
            setLoading(false);
            getCharityData();
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getCharityData(currentPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);


    useEffect(() => {
        if (search.length === 0 || search.length > 3)
            getCharityData(currentPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    // state for selected rows
    const [selectedRows, setSelectedRows] = useState([]);
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRowKeys);
        }
    };

    return (
        <div className="charity">
            <div className="table-pages-header large">
                <div className="search-bar">
                    <i className="fa fa-search"/>
                    <input type="text" value={search} onChange={e => setSearch(e.target.value)}
                           placeholder="Search Charity"/>
                </div>

                <div className="delete-btn">
                    {selectedRows.length >= 1 ? (
                        <GeneralButton buttonText="Delete" buttonClick={deleteMultiple}/>
                    ) : (
                        ""
                    )}
                </div>
            </div>

            <div className="table-pages-header mobile">
                {selectedRows.length >= 1 ? (
                    ""
                ) : (
                    <div className="search-bar">
                        <i className="fa fa-search"/>
                        <input type="text" value={search} onChange={e => setSearch(e.target.value)}
                               placeholder="Search Charity"/>
                    </div>
                )}

                <div className="delete-btn">
                    {selectedRows.length >= 1 ? (
                        <GeneralButton buttonText="Delete" buttonClick={deleteMultiple}/>
                    ) : (
                        ""
                    )}
                </div>
            </div>

            <div className="charity-table">
                <Table
                    columns={columns}
                    pagination={false}
                    className="table"
                    rowSelection={{
                        type: "checkbox",
                        ...rowSelection
                    }}
                    scroll={{x: "1200px", y: "calc(100vh - 300px)"}}
                    dataSource={charityData.map(post => ({
                        key: post.id,
                        id: post.id,
                        Name: (
                            <div>
                                <img className="images" alt="donation-icon" src={post.image}/>
                                {post.name}
                            </div>
                        ),
                        Description:
                            post.description.length >= 100
                                ? post.description.substring(0, 100) + "...."
                                : post.description,
                        Action: (
                            <div className="more">
                                <span className="material-icons more-icon">more_horiz</span>
                                <ul className="dropdown">
                                    <li className="option" onClick={() => editCharity(post)}>
                                        Edit
                                    </li>
                                    <li className="option" onClick={() => deleteCharity(post.id)}>
                                        Delete
                                    </li>
                                </ul>
                            </div>
                        )
                    }))}
                />
                {totalCharity && (
                    <Pagination
                        defaultCurrent={1}
                        total={totalCharity}
                        onChange={page => {
                            setCurrentPage(page);
                        }}
                        style={{textAlign: "right", marginTop: "20px"}}
                    />
                )}
            </div>

            {addCharityModal && (
                <div className="modal-wrapper">
                    <div className="backdrop"></div>
                    <div className={`modal ${addCharityModalAnimation ? "fade-in" : "fade-out"}`}
                    >
                        <Form
                            formTitle="Add Charity"
                            btnText="Add Charity"
                            onFormSubmit={addNewCharity}
                            closeModal={() => {
                                setAddCharityModalAnimation(false);
                                setTimeout(() => setAddCharityModal(false), 600);
                            }}
                        />
                    </div>
                </div>
            )}

            {updateCharityModal && (
                <div className="modal-wrapper">
                    <div className="backdrop"></div>
                    <div className={`modal  ${editCharityAnimation ? "fade-in" : "fade-out"} `}>
                        <Form
                            formTitle={`Update Charity: ${selectedCharity.name}`}
                            initialText={selectedCharity.description}
                            initialTitle={selectedCharity.name}
                            initialLanguage={selectedCharity.language}
                            btnText="Update Charity"
                            onFormSubmit={updateCharity}
                            closeModal={() => {
                                setEditCharityAnimation(false);
                                setTimeout(() => setUpdateCharityModal(false), 600);
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Charities;
