export const LOGIN = "/";
export const RECOVER = "/recover";
export const POST_AUTH_ROUTES = "/dashboard";

// Post Authentication routes
const dashboardEntry = "/dashboard";
export const DASHBOARD = `${dashboardEntry}/`;
export const DELEGATE = `${dashboardEntry}/delegate`;
export const CUSTOMERS = `${dashboardEntry}/customers`;
export const ANALYTICS = `${dashboardEntry}/analytics`;
export const POST = `${dashboardEntry}/posts`;
export const CHARITY = `${dashboardEntry}/charity`;
export const DONATIONS = `${dashboardEntry}/donations`;
export const CHAT = `${dashboardEntry}/chat`;
export const LIST = `${dashboardEntry}/tasks`;

// Settings Routes
const settingsEntry = `${dashboardEntry}/settings`;
export const SETTINGS = `${settingsEntry}`;
export const PREFERENCE = `${settingsEntry}/`;
export const PROFILE = `${settingsEntry}/profile`;
export const GEO_FENCE = `${settingsEntry}/geofene`;
export const AUTO_ALLOCTION = `${settingsEntry}/auto`;
export const RECURRING_RULE = `${settingsEntry}/recurring-rule-list`;
export const TEAMS = `${settingsEntry}/teams`;
export const MANAGERS = `${settingsEntry}/managers`;
export const APP = `${settingsEntry}/app`;
export const NOTIFICATIONS = `${settingsEntry}/notifications`;
export const ACCESS_CONTROL = `${settingsEntry}/control`;
export const SURGE = `${settingsEntry}/surge`;
