import "./styles.scss";
import {Drawer} from "antd";
import CloseButton from "../closeBotton/closeBotton";
// import TaskCard from "../taskCard/taskCard";
import {CustomerContext} from "../../context/customerContext/customerContext";
import {useContext} from "react";
// import { assignedTask } from "../../pages/postAuth/dashboard/data";

const CustomerProfile = () => {
    const {
        customerDetails,
        setCustomerDetails,
        // showCustomerTaskDetailsFunction
    } = useContext(CustomerContext);

    return (
        <Drawer
            headerStyle={{display: "none"}}
            placement="right"
            closable={false}
            onClose={() => setCustomerDetails(false)}
            maskClosable={false}
            visible={customerDetails}
            width={370}
            className="half-drawer customer-details"
        >
            <div className="header-avater">
                <img src="/image/default-user.png" alt=""/>
                <span className="username">UserName</span>
                <div className="close-btn" onClick={() => setCustomerDetails(false)}>
                    <CloseButton/>
                </div>
            </div>

            <div className="header-details">
                <div className="details phone-number">
                    <span className="material-icons">phone</span>
                    <p>+249037399392</p>
                </div>

                <div className="details phone-number">
                    <span className="material-icons">email</span>
                    <p>Not Avialble</p>
                </div>

                <div className="details phone-number">
                    <span className="material-icons">place</span>
                    <p>https://maps.google.com/?q=27.177485,49.534725</p>
                </div>

                <div className="details phone-number">
                    <span className="material-icons">star</span>
                    <p>Not Avialable</p>
                </div>
            </div>

            <div className="search-box">
                <i className="fa fa-search icon"></i>
                <input type="text" placeholder="Search by task address"/>
            </div>

            <div className="tasks">
                {
                    //   assignedTask.map(task => (
                    //   <TaskCard
                    //     task={assignedTask}
                    //     showMore={() => showCustomerTaskDetailsFunction(task)}
                    //     key={task.id}
                    //   />
                    // ))
                }
            </div>
        </Drawer>
    );
};

export default CustomerProfile;
