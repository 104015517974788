import Request from "../utils/Request";
import {showSuccess} from "../utils";

class AppService {
    getTasks = (page = 1, search = '') =>
        Request().get(`admin/tasks/all?page=${page}${search && '&search=' + search}`);
    updateCustomer = (id, formdata) => Request().post(`admin/customers/${id}`, formdata)
        .then(() => {
            showSuccess("Updated successfully")
        });

}

export default new AppService();


