import axios from "axios";


const baseURL = 'https://kiswah.yorubacoach.com/api/';


/**
 * Configure axios to automatically add baseUrl and authorization to needed api request
 */
function Request() {
    let token =  `Bearer ${localStorage.getItem("user-token")}`;
    // window.token = Auth.getStoredToken()
    if (token) {
        const headers = {Authorization: token, Accept: 'application/json'};
        return axios.create({baseURL, headers})
    } else return axios.create({baseURL})
}

export default Request
