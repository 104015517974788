import React from 'react';
import './styles.scss'

const Loading = ({styles}) => {
    return (
        <div className="loading">
            <div className="backdrop"></div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div className="modal">
                    <div className="loader" style={{...styles}}/>
                </div>
            </div>
        </div>
    );
}

Loading.prototype = {
    styles: {}
}

export default Loading;