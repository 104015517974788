import {createContext, useState} from "react";
import axios from "axios";
import {useContext} from "react";
import {GeneralContext} from "../generalContext/generalContext";
import * as api from "../../constants/baseUri";

export const CustomerContext = createContext();

const CustomerContextProvider = props => {
    const BASE_URI = api.BASE_URI;

    const filterOneOptions = ["Customer Id", "Name", "Phone", "Email", "Address"];
    const [filterOne, setFilterOne] = useState(filterOneOptions[0]);

    const filterTwoOptions = [
        "Exact Search",
        "Start with",
        "Ends with",
        "Contains"
    ];
    const [filterTwo, setFilterTwo] = useState(filterTwoOptions[0]);

    const [headerFilter, setHeaderFilter] = useState("All Customers");

    // get loading state from auth context
    const {setLoading, showAlert} = useContext(GeneralContext);

    // state for current page
    let [currentPage, setCurrentPage] = useState(1);

    // state for selected rows
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedCustomer, setCustomer] = useState({});

    // state for customers data
    const [customers, setCustomers] = useState([]);
    const [totalCustomers, setTotalCustomer] = useState(null);
    // get customers function
    const getCustomerData = async (paagNumber = currentPage, search = '') => {
        setLoading(true);
        if (search.length > 3 && currentPage !== 1) {
            setCurrentPage(1)
        }
        const config = {headers: {Authorization: `Bearer ${localStorage.getItem("user-token")}`}};
        try {
            const dataReturned = await axios.get(`${BASE_URI}/customers?page=${paagNumber}&search=${search}`, config);
            setLoading(false);
            setCustomers(dataReturned.data.data.data);
            setTotalCustomer(dataReturned.data.data.total);
        } catch (err) {
            showAlert("error", err.message);
        }
    };

    // state for add customer moadal
    const [addCustomerModal, setAddCustomerModal] = useState(false);
    const [addCustomerAnimation, setAddCustomerAnimation] = useState(false);

    // state for edit customer modal
    const [editCustomerModal, setEditCustomerModal] = useState(false);
    const [editCustomerAnimation, setEditCustomerAnimation] = useState(false);

    // state for customer details drawer
    const [customerDetails, setCustomerDetails] = useState(false);

    // state for customer task details drawer
    const [showCustomerTaskDetails, setShowCustomerTaskDetails] = useState(false);

    // state for customer task details
    const [taskDetails, setCustomerTaskDetails] = useState(null);

    const showCustomerTaskDetailsFunction = task => {
        setShowCustomerTaskDetails(true);
        setCustomerTaskDetails(task);
    };

    return (
        <CustomerContext.Provider
            value={{
                getCustomerData,
                currentPage,
                customers,
                totalCustomers,
                setCurrentPage,
                selectedRows,
                setSelectedRows,
                setAddCustomerModal,
                addCustomerModal,
                setEditCustomerModal,
                editCustomerModal,
                filterOne,
                setFilterOne,
                filterTwo,
                setFilterTwo,
                filterOneOptions,
                filterTwoOptions,
                headerFilter,
                setHeaderFilter,
                customerDetails,
                setCustomerDetails,
                showCustomerTaskDetails,
                showCustomerTaskDetailsFunction,
                taskDetails,
                setShowCustomerTaskDetails,
                addCustomerAnimation,
                setAddCustomerAnimation,
                editCustomerAnimation,
                setEditCustomerAnimation,
                setCustomer, selectedCustomer,
            }}
        >
            {props.children}
        </CustomerContext.Provider>
    );
};

export default CustomerContextProvider;
