import React, {useState} from "react";
import "./styles.scss";
import CancelButton from "../generalButton/cancelButton";
import AcceptButton from "../generalButton/AcceptButton";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const Editor = ({value, onChange, ref}) => <CKEditor
    editor={ClassicEditor}
    data={value}
    style={{height: 300}}
    onReady={editor => ref}
    onChange={(event, editor) => {
        const data = editor.getData();
        if (onChange) onChange(data);
    }}
/>
const PostForm =
    ({
         formTitle, initialUrl, btnText, onFormSubmit, closeModal, initialLanguage,
         initialTitle, initialText, initialVariation, initialCTA, initialTextAr, initialTitleAr
     }) => {
        const submit = e => {
            e.preventDefault();
            if (title && text) {
                onFormSubmit(e);
            } else {
                setFormError(true);
                setTimeout(() => {
                    setFormError(false);
                }, 2500);
            }
        };

        let [title, setTitle] = useState(initialTitle || "");
        let [text, setText] = useState(initialText || "");

        let [text_ar, setTextAr] = useState(initialTextAr || "");
        let [title_ar, setTitleAr] = useState(initialTitleAr || "");
// eslint-disable-next-line
        let [language, setLanguage] = useState(initialLanguage || "en");
        let [variation, setVariation] = useState(initialVariation || "news");
        let [url, setUrl] = useState(initialUrl || "");
        let [cta_text, setCTA] = useState(initialCTA || "");
        let [formError, setFormError] = useState(false);

        return (
            <React.Fragment>
                <div className="post-form">
                    <div className="form-header fs-30">
                        <h3>{formTitle}</h3>
                    </div>
                    {formError && <p style={{color: "red", fontSize: "12px"}}>Fill all required field</p>}
                    <form onSubmit={submit} method="post" encType="multipart/form-data">
                        <div className="form-row">
                            <div className="form-col">
                                <label className="required">Title: </label>
                                <input name="title" type="text" value={title} onChange={e => setTitle(e.target.value)}/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-col">
                                <label className="required">Text: </label>
                                <Editor value={text} onChange={setText}/>
                                <textarea hidden name="text" value={text}/>
                            </div>
                        </div>
                        <hr style={{marginTop: 20}}/>
                        <div className="form-row">
                            <div className="form-col">
                                <label className="required">Title in Arabic: </label>
                                <input required name="title_ar" type="text" value={title_ar}
                                       onChange={e => setTitleAr(e.target.value)}/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-col">
                                <label className="required">Text in Arabic: </label>
                                <Editor value={text_ar} onChange={setTextAr}/>
                                <textarea hidden name="text_ar" value={text_ar}/>
                            </div>
                        </div>

                        <div className="form-row">
                            {/*<div className="form-col">*/}
                            {/*    <label>Language: </label>*/}
                            {/*    <select*/}
                            {/*        name="language"*/}
                            {/*        value={language}*/}
                            {/*        onChange={e => setLanguage(e.target.value)}*/}
                            {/*    >*/}
                            {/*        <option value="en">English</option>*/}
                            {/*        <option value="ar">Arabic</option>*/}
                            {/*    </select>*/}
                            {/*</div>*/}
                            <div className="form-col">
                                <label>Variation:</label>
                                <select
                                    name="variation" value={variation} onChange={e => setVariation(e.target.value)}>
                                    <option value="news">News</option>
                                    <option value="awareness">Article</option>
                                    <option value="gift">Gift</option>
                                    <option value="donation">Donation</option>
                                </select>
                            </div>
                        </div>

                        {variation === 'awareness' && <>
                            <p>CTA BUTTON</p>
                            <div className="form-row">
                                <div className="form-col">
                                    <label className="required">TEXT: </label>
                                    <input name="cta[text]" type="text" value={cta_text}
                                           onChange={e => setCTA(e.target.value)}/>
                                </div>

                                <div className="form-col">
                                    <label className="required">URL LINK: </label>
                                    <input name="cta[link]" type="text" value={url}
                                           onChange={e => setUrl(e.target.value)}/>
                                </div>
                            </div>
                        </>}
                        <div className="form-row">
                            <div className="form-col">
                                <label>Select Image</label>
                                <input type="file" accept="image/*" name="image"/>
                            </div>
                        </div>

                        <div className="form-footer">
                        <span style={{marginRight: "10px"}}>
                          <CancelButton buttonText="Cancel" buttonClick={closeModal}/>
                        </span>
                            <AcceptButton buttonText={btnText} submit/>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    };

export default PostForm;
