import React, {useContext} from 'react';
import './styles.scss'
import DashboardMap from '../../pages/postAuth/dashboard/dashboardMap/dashboardMap'
import CancelButton from '../generalButton/cancelButton';
import AcceptButton from '../generalButton/AcceptButton';
import {CustomerContext} from "../../context/customerContext/customerContext";
import {catchError} from "../../utils";
import {GeneralContext} from "../../context/generalContext/generalContext";
import axios from "axios";
import {BASE_URI} from "../../constants/baseUri";

const CustomerForm = ({formHeading, cancelBtn}) => {
    const {selectedCustomer, setCustomer, getCustomerData, currentPage} = useContext(CustomerContext)
    const {setLoading,} = useContext(GeneralContext)
    const setState = ({target: {value, name, ...x}}) => {
        const newOb = {...selectedCustomer}
        newOb[name] = value
        setCustomer(newOb)
    }
    const props = {};

    if (selectedCustomer.locations.length) {
        props['lat'] = Number(selectedCustomer.locations[0].latitude);
        props['lng'] = Number(selectedCustomer.locations[0].longitude);
        props['zoom'] = 15;
    }
    const handleOnSubmit = async (e) => {
        e.preventDefault();
        // const formData = new FormData(e.target);

        setLoading(true);
        let data = new FormData(e.target);
        const values = Object.fromEntries(data.entries());
        let config = {
            method: "post",
            url: `${BASE_URI}/users/${selectedCustomer.uuid}`,
            headers: {Authorization: `Bearer ${localStorage.getItem("user-token")}`},
            data: values,
        };
        try {
            // eslint-disable-next-line
            await axios(config);
            setLoading(false);
            cancelBtn();
            getCustomerData(currentPage);
        } catch (err) {
            catchError(err);
            setLoading(false);
        }
    }

    return (
        <div className="customer-form">
            <div className="form-header fs-30">
                <h3>{formHeading}</h3>
            </div>
            <form onSubmit={handleOnSubmit}>
                <div className="form-row">
                    <div className="form-col">
                        <label className="required">Name:</label>
                        <input type="text" required minLength={3} name="name" onChange={setState}
                               value={selectedCustomer?.name}/>
                    </div>
                    <div className="form-col">
                        <label className="required">Phone:</label>
                        <input value={selectedCustomer?.phone} type="tel" onChange={setState}
                               required maxLength={15} name="phone"/>
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-col">
                        <label>Email:</label>
                        <input name="email" required type="email" value={selectedCustomer?.email} onChange={setState}/>
                    </div>
                    <div className="form-col">
                        <label>Username:</label>
                        <input name="username" required value={selectedCustomer?.username} onChange={setState}/>
                    </div>
                </div>

                <div className="form-row" style={{marginTop: 10}}>
                    {selectedCustomer?.name} has {selectedCustomer?.locations.length} location(s) saved
                </div>
                <div className="map">
                    <DashboardMap pins={selectedCustomer?.locations} {...props} />
                </div>

                <div className="form-footer">
                    <span style={{marginRight: '10px'}}>
                        <CancelButton buttonText="Cancel" buttonClick={cancelBtn}/></span>
                    <AcceptButton buttonText="Save" submit/>
                </div>
            </form>

        </div>
    );
}

export default CustomerForm;