import "./styles.scss";

import * as api from "../../../constants/baseUri";

import {Pagination, Table} from "antd";
import React, {useContext, useEffect, useState} from "react";

import Form from "../../../components/postForm/postForm";
import GeneralButton from "../../../components/generalButton/AcceptButton";
import {GeneralContext} from "../../../context/generalContext/generalContext";
import {PostsContext} from "../../../context/postContext/postContext";
import axios from "axios";
import {catchError} from "../../../utils";

const columns = [
    {
        title: "ID",
        dataIndex: "id",
        key: "id",
        width: 70,
        // render: text => <a>{text}</a>,
    },
    {
        title: "Title",
        dataIndex: "Title",
        key: "Title",
        width: 100,
    },
    {
        title: "Text",
        dataIndex: "Text",
        key: "text",
        width: 300,

    },
    // {
    //     title: "Language",
    //     dataIndex: "Language",
    //     key: "language",
    //     width: 80,
    // },
    {
        title: "Image",
        dataIndex: "Image",
        key: "image",
        width: 100,
    },
    {
        title: "Variation",
        dataIndex: "Variation",
        key: "variation",
        width: 90,
    },
    {
        title: "Action",
        dataIndex: "Action",
        key: "action",
        width: 90,
    },
];

const variations = {
    news: "News",
    awareness: "Articles",
    gift: "Gifts",
    donation: "Donations",
};
const News = () => {
    // api base uri
    const BASE_URI = api.BASE_URI;

    // getting values from general context Contex
    const {setLoading, showAlert} = useContext(GeneralContext);

    // getting values from general context Contex
    const {
        setAddPostModal,
        addPostModal,
        addPostAnimation,
        setAddPostAnimation,
    } = useContext(PostsContext);

    // currrent page for pagination
    const [currentPage, setCurrentPage] = useState(1);

    // state for post data
    const [postData, setPostData] = useState([]);

    // for total post
    const [totalPost, setTotalPost] = useState(null);

    // get post function
    const getPostData = async (page) => {
        setLoading(true);
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("user-token")}`,
            },
        };

        try {
            const dataReturned = await axios.get(`${BASE_URI}/news?page=${page}${search ? '&search=' + search : ''}`, config);
            setLoading(false);
            setPostData(dataReturned.data.data.data);
            setTotalPost(dataReturned.data.data.total);
        } catch (err) {
            catchError(err);
            setLoading(false);
        }
    };

    const [search, setSearch] = useState('')

    useEffect(() => {
        if (search.length > 3 || search.length === 0) {
            getPostData(currentPage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    // fuction for creating new post
    const addNewPost = async (e) => {
        setLoading(true);

        let data = new FormData(e.target);

        let config = {
            method: "post",
            url: `${BASE_URI}/news`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("user-token")}`,
            },
            data: data,
        };

        try {
            // eslint-disable-next-line
            const dataReturned = await axios(config);
            setLoading(false);
            showAlert("success", "Post added");
            setAddPostModal(false);
            getPostData(currentPage);
        } catch (err) {
            catchError(err);
            setLoading(false);
        }
    };

    // modal state for update news
    const [editPostModal, setEditPostModal] = useState(false);

    // animation state for edit news modal
    const [editPostAnimation, setEditPostAnimation] = useState(false);

    // state for selected post
    const [selectedPost, setSelectedPost] = useState({});

    const editPost = (post) => {
        setEditPostAnimation(true);
        setEditPostModal(true);
        setSelectedPost(post);
    };

    // function for editing news
    const updatePost = async (e) => {
        let data = new FormData(e.target);
        setLoading(true);

        let config = {
            method: "post",
            url: `${BASE_URI}/news/${selectedPost.id}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("user-token")}`,
            },
            data: data,
        };
        try {
            // eslint-disable-next-line
            await axios(config);
            setLoading(false);
            setEditPostAnimation(false);
            showAlert("success", "Post updated");

            setTimeout(() => setEditPostModal(false), 600);
            getPostData();
        } catch (err) {
            catchError(err);
            setLoading(false);
        }
    };

    // function for deleting news
    const deletePost = async (id) => {
        setLoading(true);
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("user-token")}`,
            },
        };

        try {
            // eslint-disable-next-line
            let datareturned = await axios.delete(`${BASE_URI}/news/${id}`, config);

            setLoading(false);
            showAlert("success", "Post Deleted");
            getPostData();
        } catch (err) {
            catchError(err);
            setLoading(false);
        }
    };

    // function for deleting multile news
    const deleteMultiple = () => {
        setLoading(true);
        const config = {
            headers: {Authorization: `Bearer ${localStorage.getItem("user-token")}`,},
        };
        try {
            // eslint-disable-next-line
            axios.all(selectedRows.map((postId) => {
                axios.delete(`${BASE_URI}/news/${postId}`, config);
            }));

            setSelectedRows([]);
            showAlert("success", "Posts Deleted");
            setLoading(false);
            getPostData(currentPage);

        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    useEffect(() => {
        getPostData(currentPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);


    // state for selected rows
    const [selectedRows, setSelectedRows] = useState([]);
    const rowSelection = {onChange: (selectedRowKeys, selectedRows) => setSelectedRows(selectedRowKeys)};

    return (
        <div className="post">
            <div className="table-pages-header large">
                <div className="search-bar">
                    <i className="fa fa-search"/>
                    <input type="text" value={search} onChange={e => setSearch(e.target.value)}
                           placeholder="Search Post"/>
                </div>

                <div className="delete-btn">
                    {selectedRows.length >= 1 ? (
                        <GeneralButton buttonText="Delete" buttonClick={deleteMultiple}/>
                    ) : (
                        ""
                    )}
                </div>
            </div>

            <div className="table-pages-header mobile">
                {selectedRows.length >= 1 ? (
                    ""
                ) : (
                    <div className="search-bar">
                        <i className="fa fa-search"></i>
                        <input type="text" value={search} onChange={e => setSearch(e.target.value)}
                               placeholder="Search Post"/>
                    </div>
                )}

                <div className="delete-btn">
                    {selectedRows.length >= 1 ? (
                        <GeneralButton buttonText="Delete" buttonClick={deleteMultiple}/>) : null}
                </div>
            </div>

            <div className="post-table">
                <Table
                    columns={columns}
                    pagination={false}
                    className="table"
                    rowSelection={{
                        type: "checkbox",
                        ...rowSelection,
                    }}
                    scroll={{x: "1200px", y: "calc(100vh - 300px)"}}
                    dataSource={postData.map((post) => ({
                        key: post.id,
                        id: post.id,
                        Title: (
                            <div>
                                English: {post.title}
                                <br/>
                                <br/>
                                Arabic: {post.title_ar}
                            </div>
                        ),
                        Text:
                            (
                                <div>
                                    English: {post.text.length >= 100
                                    ? post.text.substring(0, 100) + "...."
                                    : post.text}
                                    <br/>
                                    <br/>
                                    Arabic: {post?.text_ar?.length >= 100
                                    ? post?.text_ar?.substring(0, 100) + "...."
                                    : post?.text_ar}
                                </div>
                            ),
                        // Language: post.language,
                        Image: (
                            <div>
                                <img  style={{width: '100%'}} alt="donation-icon"  src={post.image}     />
                            </div>
                        ),
                        Variation: variations[post.variation],
                        Action: (
                            <div className="more">
                                <span className="material-icons more-icon">more_horiz</span>
                                <ul className="dropdown">
                                    <li className="option" onClick={() => editPost(post)}>
                                        Edit
                                    </li>
                                    <li className="option" onClick={() => deletePost(post.id)}>
                                        Delete
                                    </li>
                                </ul>
                            </div>
                        ),
                    }))}
                />
                {totalPost && (
                    <Pagination
                        defaultCurrent={1}
                        total={totalPost}
                        onChange={(page) => {
                            setCurrentPage(page);
                        }}
                        style={{textAlign: "right", marginTop: "20px"}}
                    />
                )}
            </div>

            {addPostModal && (
                <div className="modal-wrapper">
                    <div className="backdrop"></div>
                    <div className={`modal ${addPostAnimation ? "fade-in" : "fade-out"}`}>
                        <Form
                            formTitle="Add Post"
                            btnText="Add Post"
                            onFormSubmit={addNewPost}
                            closeModal={() => {
                                setAddPostAnimation(false);
                                setTimeout(() => setAddPostModal(false), 600);
                            }}
                        />
                    </div>
                </div>
            )}

            {editPostModal && (
                <div className="modal-wrapper">
                    <div className="backdrop"></div>
                    <div className={`modal ${editPostAnimation ? "fade-in" : "fade-out"}`}>
                        <Form
                            formTitle={`Update Post: ${selectedPost.title}`}
                            initialText={selectedPost.text}
                            initialLanguage={selectedPost.type}
                            initialTitle={selectedPost.title}
                            initialTitleAr={selectedPost?.title_ar}
                            initialTextAr={selectedPost?.text_ar}
                            initialVariation={selectedPost.variation}
                            initialUrl={selectedPost?.cta?.url}
                            initialCTA={selectedPost?.cta?.text}
                            btnText="Update Post"
                            onFormSubmit={updatePost}
                            closeModal={() => {
                                setEditPostAnimation(false);
                                setTimeout(() => setEditPostModal(false), 600);
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default News;
