import './styles.scss'

const CloseButton = () => {
  return (
    <span className="material-icons close-btn">
      close
    </span>
   );
}
 
export default CloseButton;