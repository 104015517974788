import React from "react";
import {
  DASHBOARD,
  // DELEGATE,
  CUSTOMERS,
  // ANALYTICS,
  // SETTINGS,
  POST,
  CHARITY,
  DONATIONS,
  GEO_FENCE,
} from "../../routes";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThLarge,
  // faUser,
  faUserFriends,
  // faChartBar,
  faCog,
  faNewspaper,
  faDonate,
  faHandHoldingHeart,
} from "@fortawesome/free-solid-svg-icons";

import Dashboard from "./dashboard/dashboard";
// import Delegates from "./delegate/delegates";
import Customers from "./customers/customers";
// import Analytics from "./analytics/analytics";
// import Settings from "./settings";
import GeoFence from "./settings/geoFence/geoFence";
import Post from "./posts/posts";
import Charity from "./charity/charity";
import Donations from "./donations/donations";

export const dashRoutes = [
  {
    name: "Dashboard",
    icon: <FontAwesomeIcon icon={faThLarge} />,
    path: DASHBOARD,
    component: Dashboard,
    exact: true,
  },
  // {
  //   name: "Delegate",
  //   icon: <FontAwesomeIcon icon={faUser} />,
  //   path: DELEGATE,
  //   component: Delegates,
  //   exact: true
  // },
  {
    name: "Customers",
    icon: <FontAwesomeIcon icon={faUserFriends} />,
    path: CUSTOMERS,
    component: Customers,
    exact: true,
  },
  // {
  //   name: "Analytics",
  //   icon: <FontAwesomeIcon icon={faChartBar} />,
  //   path: ANALYTICS,
  //   component: Analytics,
  //   exact: true
  // },
  {
    name: "Post",
    icon: <FontAwesomeIcon icon={faNewspaper} />,
    path: POST,
    component: Post,
    exact: true,
  },
  {
    name: "Charity",
    icon: <FontAwesomeIcon icon={faHandHoldingHeart} />,
    path: CHARITY,
    component: Charity,
    exact: true,
  },
  {
    name: "Donations",
    icon: <FontAwesomeIcon icon={faDonate} />,
    path: DONATIONS,
    component: Donations,
    exact: true,
  },
  // {
  //   name: "Settings",
  //   icon: <FontAwesomeIcon icon={faCog} />,
  //   path: SETTINGS,
  //   component: Settings,
  //   // do not add the exat property to the route containing nested route
  //   exact: false,
  // },
  {
    name: "Geo Fence",
    icon: <FontAwesomeIcon icon={faCog} />,
    path: GEO_FENCE,
    component: GeoFence,
    exact: true,
  },
];
